






























































































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";
import LanguageSelect from "./misc/LanguageSelect/LanguageSelect.vue";
import DayNightToggle from "@/components/misc/DayNightToggle.vue";
import NetworkMenu from "./NetworkSettings/NetworkMenu.vue";
import ConfirmLogout from "@/components/modals/ConfirmLogout.vue";
import AccountMenu from "@/components/wallet/sidebar/AccountMenu.vue";
@Component({
  components: {
    AccountMenu,
    NetworkMenu,
    DayNightToggle,
    ConfirmLogout,
    LanguageSelect,
  },
})
export default class Navbar extends Vue {
  isDrawer: boolean = false;

  get isAuth(): boolean {
    return this.$store.state.isAuth;
  }

  logout(): void {
    // @ts-ignore
    this.$refs.logout.open();
  }
}
