<template>
  <div class="analytics" v-show="showConsentModal">
    <div class="analytics_item">
      <p class="analytics_msg">
        {{ $t("analytics.cookie_desc") }}
        <a
          href="https://dijets.io/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("analytics.privacy_policy") }}
        </a>
        .
      </p>
      <div class="analytics_actions">
        <button
          type="button"
          @click="onReject"
          data-cy="reject_analytics"
          class="flex border-none rounded bg-transparent p-0 outline-none outline-offset-1 transition-all focus:outline-4 focus:outline-scale-600"
        >
          <span
            class="relative cursor-pointer inline-flex items-center space-x-2 text-center font-regular transition ease-out duration-200 rounded outline-none transition-all outline-0 focus-visible:outline-4 focus-visible:outline-offset-1 text-scale-1200 bg-scale-100 hover:bg-scale-300 bordershadow-scale-600 hover:bordershadow-scale-700 bordershadow-scale-700 hover:bordershadow-scale-800 bg-scale-500 hover:bg-scale-600 focus-visible:outline-brand-600 shadow-sm text-xs px-2.5 py-1"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
              stroke="none"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="sbui-icon text-scale-900"
            >
              <path
                d="M16.334 2H7.665C4.268 2 2 4.433 2 7.916v8.168C2 19.571 4.262 22 7.665 22h8.669C19.738 22 22 19.57 22 16.084V7.916C22 4.43 19.738 2 16.334 2ZM7.665 3.5h8.67c2.55 0 4.165 1.735 4.165 4.416v8.168c0 2.681-1.615 4.416-4.166 4.416H7.665c-2.55 0-4.165-1.735-4.165-4.416V7.916C3.5 5.24 5.12 3.5 7.665 3.5Zm1.406 5.562a.75.75 0 0 1 .976-.072l.084.073L12 10.93l1.866-1.866a.75.75 0 0 1 1.133.976l-.072.084-1.866 1.866 1.867 1.869a.75.75 0 0 1-.976 1.133l-.084-.073-1.868-1.868-1.865 1.865A.75.75 0 0 1 9 13.941l.072-.084 1.865-1.866-1.867-1.868a.75.75 0 0 1 0-1.06Z"
              ></path></svg
            ><span class="truncate">Reject</span></span
          >
        </button>
        <button
          type="button"
          @click="onAccept"
          data-cy="accept_analytics"
          class="flex border-none rounded bg-transparent p-0 outline-none outline-offset-1 transition-all focus:outline-4 focus:outline-scale-600"
        >
          <span
            class="relative cursor-pointer inline-flex items-center space-x-2 text-center font-regular transition ease-out duration-200 rounded outline-none transition-all outline-0 focus-visible:outline-4 focus-visible:outline-offset-1 text-scale-1200 bg-scale-100 hover:bg-scale-300 bordershadow-scale-600 hover:bordershadow-scale-700 bordershadow-scale-700 hover:bordershadow-scale-800 bg-scale-500 hover:bg-scale-600 focus-visible:outline-brand-600 shadow-sm text-xs px-2.5 py-1"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
              stroke="none"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="sbui-icon text-scale-900"
            >
              <path
                d="M16.334 2H7.665C4.268 2 2 4.433 2 7.916v8.168C2 19.571 4.262 22 7.665 22h8.668C19.738 22 22 19.57 22 16.084V7.916C22 4.43 19.738 2 16.334 2ZM7.665 3.5h8.67c2.55 0 4.165 1.735 4.165 4.416v8.168c0 2.681-1.615 4.416-4.167 4.416H7.665c-2.55 0-4.165-1.735-4.165-4.416V7.916C3.5 5.24 5.12 3.5 7.665 3.5Zm8.425 5.597a.75.75 0 0 0-1.06 0l-4.217 4.215L8.97 11.47l-.084-.073a.75.75 0 0 0-.976 1.134l2.374 2.373.084.072a.75.75 0 0 0 .976-.073l4.746-4.745.073-.085a.75.75 0 0 0-.073-.976Z"
              ></path></svg
            ><span class="truncate">Accept</span></span
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // if the user never accepted or rejected, we show the prompt
      showConsentModal: localStorage.getItem("consentsToAnalytics") === null,
    };
  },
  computed: {},
  methods: {
    onAccept() {
      localStorage.setItem("consentsToAnalytics", true);
      // @ts-ignore
      this.showConsentModal = false;

      return;
    },
    onReject() {
      localStorage.setItem("consentsToAnalytics", false);
      // @ts-ignore
      this.showConsentModal = false;

      return;
    },
  },
};
</script>
<style scoped>
.analytics {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px 3vw;
  z-index: 9;
}
.bg-scale-500 {
  background-color: #25242b;
}

span.relative.cursor-pointer.inline-flex.items-center.space-x-2.text-center.font-regular.transition.ease-out.duration-200.rounded.outline-none.transition-all.outline-0.focus-visible\:outline-4.focus-visible\:outline-offset-1.text-scale-1200.bg-scale-100.hover\:bg-scale-300.bordershadow-scale-600.hover\:bordershadow-scale-700.bordershadow-scale-700.hover\:bordershadow-scale-800.bg-scale-500.hover\:bg-scale-600.focus-visible\:outline-brand-600.shadow-sm.text-xs.px-2\.5.py-1:hover {
  background: rgb(58 58 76 / 20%);
  box-shadow: rgb(0 0 0 / 1%) 0 0 0 0, rgb(0 0 0 / 1%) 0 0 0 0,
    rgb(0 0 0 / 22%) 0 1px 1px 0, #354254b0 0 0 0 1px, rgb(0 0 0 / 1%) 0 0 0 0,
    rgb(0 0 0 / 1%) 0 0 0 0, rgb(64 68 82 / 8%) 0 2px 5px 0;
}

.bordershadow-scale-700 {
  box-shadow: rgb(0 0 0 / 1%) 0 0 0 0, rgb(0 0 0 / 1%) 0 0 0 0,
    rgb(0 0 0 / 22%) 0 1px 1px 0, #2d3034 0 0 0 1px, rgb(0 0 0 / 1%) 0 0 0 0,
    rgb(0 0 0 / 1%) 0 0 0 0, rgb(64 68 82 / 8%) 0 2px 5px 0;
}

.analytics_actions {
  display: grid;
  grid-template-columns: 120px 120px;
  align-items: center;
  padding: 2px 14px 10px 14px;
  justify-items: center;
  justify-content: center;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(255 255 255 / 90%);
  font-weight: 500;
}

.analytics_item {
  border-radius: 6px;
  margin: auto;
  margin-bottom: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.4);
  background-color: #131219;
  width: 646px;
  font-size: 13px !important;
}

.button_form_cancel {
  margin-right: 16px !important;
}

.button_primary {
  padding: 4px 8px;
}

.analytics_msg {
  padding: 16px;
  font-size: 11.5px;
  line-height: 18px;
  text-align: center;
  color: #868f97;
}

p {
  margin: 0 !important;
}

@media only screen and (max-width: 600px) {
  .analytics {
    height: min-content;
    width: 100%;
    left: 0;
    top: 0;
    padding: 12px 3vw;
    z-index: 111;
  }

  .analytics_item {
    max-width: unset;
    width: 100%;
  }
}
</style>