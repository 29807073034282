














import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import NetworkRow from "./NetworkRow.vue";
import { AvaNetwork } from "@/js/AvaNetwork";

@Component({
  components: {
    NetworkRow,
  },
})
export default class ListPage extends Vue {
  get networks(): AvaNetwork[] {
    return this.$store.getters["Network/allNetworks"];
  }

  onEdit(net: AvaNetwork) {
    this.$emit("edit", net);
  }
}
