













import { Vue, Component, Prop } from "vue-property-decorator";
import { iUserAccountEncrypted } from "@/store/types";
import Identicon from "@/components/misc/Identicon.vue";

@Component({
  components: {
    Identicon,
  },
})
export default class AccountsFound extends Vue {
  accounts: iUserAccountEncrypted[] = [];

  created() {
    this.refreshAccounts();
  }
  refreshAccounts() {
    let accountsRaw = localStorage.getItem("accounts") || "{}";
    this.accounts = JSON.parse(accountsRaw) || [];
  }

  selectAccount(index: number) {
    this.$router.push(`/access/account/${index}`);
  }
}
